import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ALERT_LOADING_EVENT } from 'src/app/services/alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnDestroy {
  private _loadings: boolean[] = [];
  private _subscribe: Subscription;

  constructor() {
    this._subscribe = ALERT_LOADING_EVENT.subscribe(ev => {
      if (ev) this._loadings.push(ev);
      else this._loadings.pop();
    });
  }

  ngOnDestroy(): void {
    this._subscribe.unsubscribe();
  }

  /** when loading */
  get isLoading() { return this._loadings.length > 0; }
}
