import { AuthService } from "../services/auth.service";
import { ActivatedRouteSnapshot, Router, UrlTree, RouterStateSnapshot } from "@angular/router";
import { AppURL } from "../app.url";
// import { AuthenURL } from "../pages/authen/authen.url";
import { inject } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { KeycloakService } from "keycloak-angular";

/** Authentication guard for login routing */
export async function AuthGuard(state: ActivatedRouteSnapshot, route: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const _rout = inject(Router);
    const _auth = inject(AuthService);
    let ckToken: any = _auth.getTokens()
    console.log(ckToken);

    if (ckToken.__zone_symbol__value) return true
    else return false


    // const _urlt = _rout.createUrlTree(['/', AppURL.authen, AuthenURL.signin], { queryParams: { redirect: route.url } });
    // const _reqs = _auth.getUserLogin(true);
    // return _auth.getAccessToken.token ? await lastValueFrom(_reqs).then(() => true).catch(() => _urlt) : _urlt;

};



/** Authentication guard for not login routing */
export function UnAuthGuard() {
    // const _auth = inject(AuthService);
    // const _rout = inject(Router);
    // if (!_auth.getAccessToken.token) return true;
    // return _rout.createUrlTree(['/', AppURL.authen, AuthenURL.profile]);
    return false
}