import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfig } from './configs/http.config';
import { ToastComponent } from './shareds/toast/toast.component';
import { LoadingComponent } from './shareds/loading/loading.component';
import { SimpleLayoutModule } from './layouts/simple-layout/simple-layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { UploadFileComponent } from './shareds/upload-file/upload-file.component';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';
import { CompanyComponent } from './pages/company/company.component';
import { SettigCompanyComponent } from './shareds/settig-company/settig-company.component';
import { ViewVersionComponent } from './shareds/view-version/view-version.component';
import { CreateAppComponent } from './shareds/view-version/create-app/create-app.component';
import { PublicVersionComponent } from './shareds/public-version/public-version.component';
import { ViewVersionModule } from './shareds/view-version/view-version.module';
import { SettigCompanyModule } from './shareds/settig-company/settig-company.module';
import { PublicVersionModule } from './shareds/public-version/public-version.module';


function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://api-dev.legitid.org/auth/',
        realm: 'Inventory',
        clientId: 'dashboard',
        // clientId: 'bookingX',

      },
      initOptions: {
        onLoad: 'login-required',  // allowed values 'login-required', 'check-sso';
        // flow: "standard"          // allowed values 'standard', 'implicit', 'hybrid';
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    CompanyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingComponent,
    ToastComponent,
    SimpleLayoutModule,
    KeycloakAngularModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    TableModule,
    ViewVersionModule,
    SettigCompanyModule,
    PublicVersionModule


  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfig, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
