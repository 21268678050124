<div class="container" [formGroup]="form">
    <!-- <div class="row">
        <div class="col-12 text-center">
            <div class="box-image" [ngClass]="{'active':viewImage}">
                <app-upload-file (FileUpload)="uploadFile($event)" [width]="827" [height]="827">
                    <ng-container *ngIf="!viewImage">
                        <span class="material-symbols-outlined fs-1 mt-3">
                            image
                        </span>
                        <br>
                        <span>Logo</span>
                    </ng-container>
                    <img *ngIf="viewImage" [src]="viewImage" alt="">

                </app-upload-file>
            </div>
            <span>Size 827 x 827</span>
            <br><br>
        </div>
    </div> -->
    <div class="row justify-content-center ">
        <div class="col-12">
            <div class="box-name">
                <span class="p-float-label">
                    <input pInputText class="w-100 " formControlName="company_name" />
                    <label for="username">Username</label>
                </span>
            </div>
        </div>
    </div>
    <div class="row justify-content-center mt-3">
        <div class="col-12 text-center">
            <p-button label="Save" *ngIf="modelItem" (onClick)="updateCompany()"></p-button>
            <p-button label="Create" *ngIf="!modelItem" (onClick)="createCompany()"></p-button>
        </div>
    </div>
</div>