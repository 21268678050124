import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-version',
  templateUrl: './view-version.component.html',
  styleUrls: ['./view-version.component.scss']
})
export class ViewVersionComponent {
  actionView: boolean = false;
  ogid: any
  constructor(private http: HttpClient,
    private ac: ActivatedRoute,
    private router: Router) {
    this.ogid = this.ac.snapshot.params['ogid']
    if (this.ogid) this.getVersion()
  }

  verItems: any = []
  contentURL: any = environment.content;

  getVersion() {
    this.http.get(`/api/version/list/${this.ogid}`).subscribe({
      next: (res: any) => {
        this.verItems = res
        this.verItems.Items.forEach((f: any) => {
          f.download = `${this.contentURL}${this.ogid}/${f.path}`
        });
      }
    })
  }

  deleteVersion(item: any) {
    console.log(item);
    this.http.post(`/api/version/delete/${this.ogid}/${item.id}`, item).subscribe({
      next: (res: any) => {
        this.getVersion();
      }
    })
  }

  download(item: any) {
    window.location.replace(item.download)
  }

  backDashboard() {
    this.router.navigate(['/', AppURL.organization])
  }

  update(e: any) {
    if (e) {
      this.getVersion();
      this.close();
    }
  }

  close() {
    this.actionView = false
  }
}
