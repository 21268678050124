import { Injectable } from '@angular/core';
import { AppURL } from '../app.url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private http: HttpClient) {

  }




}
