<div class="box-navbar">
    <div class="box-item ">
        <div class="box-header">
            Avesta Dashboard
        </div>
        <!-- <div class="box-item pt-3 dashboard" (click)="toDashoboard()">
            <span class="material-symbols-outlined">
                dashboard
            </span>
            <span>กลับหน้าหลัก</span>
        </div> -->
    </div>
    <div class="float-end box-user" (click)="viewContact()">
        <div class="d-flex">
            <div class="box-image">
                <img src="../../../../../assets/icons/logo.png" alt="">
            </div>
            <div class="box-name ms-3 d-none d-xl-block d-lg-block d-md-block">
                <b>{{profile?.name}}</b>
                <br>
                <span>USER</span>
            </div>
        </div>
    </div>
</div>

<app-nb-contact [view]="profile" (update)="closeProfile()"></app-nb-contact>