<br><br>
<div class="container">
    <br><br>
    <div class="row">
        <div class="col-12 mb-3">
            <h1>Version App</h1>
        </div>
        <div class="col-12">
            <table class="table table-hover  ">
                <thead>
                    <tr>
                        <th>Version Name</th>
                        <th>Download</th>
                        <th class="text-end">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let v of verItems?.Items">
                        <td>{{v.name}}</td>
                        <td>
                            <div class="d-flex">
                                <!-- <span>{{v.download}}</span> -->
                                <span class="material-symbols-outlined fs-3  me-1">
                                    mobile_friendly
                                </span>
                                <a [href]="v.download">
                                    <span class="material-symbols-outlined ms-2">
                                        download
                                    </span>
                                </a>

                            </div>
                        </td>
                        <td class="text-end">
                            <span *ngIf="v.status ==1" class="text-success fw-bolder ">ON LINE</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

