import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { actionList, companyItem, sysItems, sysTm } from './company.interface';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppURL } from 'src/app/app.url';
import { CommonModule } from '@angular/common';




@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {
  constructor(private http: HttpClient,
    private router: Router) {
    this.getCompany();
    // this.getSystem();

  }
  content: string = environment.content
  actionView: boolean = false
  companyItem!: companyItem;
  getCompany() {
    this.http.get(`/api/og`).subscribe({
      next: (res: any) => {
        this.companyItem = res;

      }
    })
  }

  getSystem() {
    this.http.get(`/dashboard/api/Service/dashboard?company_id=b9d6c6f5-0aaf-4f1a-805b-583ef46fbb67&has_license=false&Page=1&LimitPage=15`).subscribe({
      next: (res: any) => {
        this.systemItems = res.data
        this.systemItems.Items.forEach((f: sysTm) => {
          if (f.service_logo) f.service_logo = environment.content + f.service_logo
        })
      }
    })
  }

  textHeader: string = ""
  systemItems!: sysItems
  // [] = [
  //   { logo: '../assets/logo/POS_0.png', name: 'POS', url: '' },
  //   { logo: '../assets/logo/Booking X_0.png', name: 'Booking', url: '' },
  //   { logo: '../assets/logo/NLChat_0.png', name: 'NLChat', url: '' },
  //   { logo: '../assets/logo/Biz soft_0.png', name: 'Bizsoft', url: '' },
  //   { logo: '../assets/logo/SURVEY BOX_0.png', name: 'Survey', url: '' },
  //   { logo: '../assets/logo/DeeAuction_0.png', name: 'DeAuction', url: '' },
  // ]

  actionUse: string = "";
  actionList: actionList = {
    license: 'license',
    system: 'system',
    company: 'company',
    team: 'team'
  }
  AddLicense() {
    this.textHeader = "Which system license is used?"
    this.actionView = true;
    this.actionUse = this.actionList.license
  }


  ToSystem(item: any) {
    this.textHeader = "Which system do you go to?"
    this.actionView = true;
    this.actionUse = this.actionList.system
    this.company = item;
  }


  AddCompany() {
    this.textHeader = ""
    this.actionView = true;
    this.actionUse = this.actionList.company
    this.company = ""
  }

  company: any;
  SettingCompany(item: any) {
    this.textHeader = ""
    this.actionView = true;
    this.actionUse = this.actionList.company
    this.company = item;
  }

  AppVersion(item: any) {
    this.router.navigate(['/', AppURL.version, item.id])
  }


  updateItem(e: any) {
    console.log(e);
    this.getCompany();
    this.close()
  }




  close() {
    // this.sysAction = ""
    this.actionUse = ""
    this.actionView = false
  }
}



