import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
  @Input('size_upload_file') size_upload_file: number = 1000;
  @Input('width') width: number = 0;
  @Input('height') height: number = 0;
  @Output('FileUpload') FileUpload = new EventEmitter()
  constructor(private alert: AlertService) { }
  ngOnInit(): void {

  }

  modelImage: any;
  uploadFile(event: any) {
    const file = event.target.files && event.target.files[0];
    if (file) {

      var reader = new FileReader();
      reader.readAsDataURL(file)
      let type = [
        { name: 'png' },
        { name: 'jpeg' },
        { name: 'pdf' },
        { name: 'vnd.android.package-archive' },
        { name: 'apk' },
      ]
      console.log(file.type.split('/'));

      if (file.type.split('/')[0] == 'image' || file.type.split('/')[0] == 'application') {
        if (type.find((fn: any) => fn.name == file.type.split('/')[1])) {
          console.log(123);
          console.log(file);
          console.log(this.modelImage);

          if (this.sizeAlert(file)) {
            let modelSize: any = this.sizeAlert(file)
            reader.onload = (event) => {
              this.modelImage = {
                file_name: file.name,
                file: file,
                base64: (<FileReader>event.target).result,
                size_name: modelSize.size_name,
                size: modelSize.size,
                size_unit: modelSize.type,
                width: 0,
                height: 0,
                type: type.find((fn: any) => fn.name == file.type.split('/')[1])?.name
              }
              if (this.modelImage.type == 'pdf') this.FileUpload.emit(this.modelImage)
              if (this.modelImage.type == 'vnd.android.package-archive') this.FileUpload.emit(this.modelImage)
            }


          }
        }
        else this.fileAlert()
      }
      else this.fileAlert()
    }
  }

  fileAlert() {
    alert('ต้องเป็นไฟล์ png / jpeg / pdf เท่านั้น')
    // this.alert.dialog('ต้องเป็นไฟล์ png / jpeg / pdf เท่านั้น')
  }

  sizeAlert(file: any) {
    // if (!+file.size) return '0 Bytes'
    const k = 1024
    const dm = 2 < 0 ? 0 : 2
    const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']
    const i = Math.floor(Math.log(file.size) / Math.log(k))
    if (sizes[i] == 'Mb' && parseFloat((file.size / Math.pow(k, i)).toFixed(dm)) > this.size_upload_file) {
      // this.alert.dialog(`ขนาดไฟล์เกิน ${this.size_upload_file} MB`)
      return null
    }
    else {
      let modelSize = {
        size_name: `${parseFloat((file.size / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`,
        size: parseFloat((file.size / Math.pow(k, i)).toFixed(dm)),
        type: sizes[i]
      }
      return modelSize
    }
  }

  check_pixels(item: any) {
    if (this.width == 0 && this.height == 0) return true
    if (this.width >= item.width && this.height >= item.height) return true
    else {
      // this.alert.dialog(`ขนาดรูปภาพต้อง ${this.width} X ${this.height} pixels`)
      return false
    }

  }

  checkImage(e: any) {
    if (this.width == 0 && this.height == 0) {
      if (this.check_pixels(this.modelImage)) this.FileUpload.emit(this.modelImage)
    }
    if (e.naturalWidth == this.width && e.naturalHeight == this.height) {
      if (this.modelImage.type != 'pdf') {
        this.modelImage.width = e.naturalWidth
        this.modelImage.height = e.naturalHeight
      }
      if (this.check_pixels(this.modelImage)) this.FileUpload.emit(this.modelImage)
    }
  //  else 

  }
}
