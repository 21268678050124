<!-- <router-outlet></router-outlet>
<app-toast></app-toast>
<app-loading></app-loading> -->
<ng-container *ngIf="app.viewPage">
    <app-navbar></app-navbar>
    <div class="side">
        <div
            [ngClass]="{'on':app.menuAction, 'off':!app.menuAction ,'pc':  checkWidth > 431,'mobile': 431 > checkWidth}">
            <app-sidebar></app-sidebar>
        </div>
    </div>
    <div class="box-content"
        [ngClass]="{'action':app.menuAction, 'hide':!app.menuAction,'pc':  checkWidth > 431,'mobile': 431 > checkWidth}">
        <router-outlet></router-outlet>
    </div>
</ng-container>
<ng-container >
    <router-outlet *ngIf="!app.viewPage"></router-outlet>
</ng-container>