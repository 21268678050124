import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-nb-contact',
  templateUrl: './nb-contact.component.html',
  styleUrls: ['./nb-contact.component.scss']
})
export class NbContactComponent {
  constructor(private kc: KeycloakService) {

  }

  profile: any
  @Input('view') set item(v: any) {
    this.profile = v
  }
  @Output("update") updateItem = new EventEmitter

  close() {
    this.profile.view = false
    this.updateItem.emit(this.profile)
  }

  logout() {
    this.kc.logout()
  }
}
