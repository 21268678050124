import { Component, Input } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';
import { AppConfig } from 'src/app/configs/app.config';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-simple-layout',
  templateUrl: './simple-layout.component.html',
  styleUrls: ['./simple-layout.component.scss']
})
export class SimpleLayoutComponent extends AppConfig {
  @Input('darkMode')
  darkMode: boolean = false;

  @Input('pageTitle')
  set _title(text: string) {
    if (!text) return;
    this._app.title.setTitle(text);
  }

  @Input('pageMetas')
  set _metas(tags: MetaDefinition[]) {
    if (!tags) return;
    this._app.meta.addTags(tags);
  }

  constructor(private _app: AppService) {
    super();
  }
}
