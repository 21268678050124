import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable, catchError, map, switchMap, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpErrorResponseApp } from "../interfaces/error.interface";
import { AuthService } from "../services/auth.service";
import { AppService } from "../services/app.service";

@Injectable()
export class HttpConfig implements HttpInterceptor {

    checkURL(url: any) {
        if (url.indexOf("https") == 0) return url
        if (url.indexOf("http") == 0) return url
        else return `${environment.apiUrl}${url}`
    }

    requestURL: string = ""
    constructor(
        private _auth: AuthService,
        private _app: AppService,
    ) { }

    appendURL: string = '';

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.appendURL = /http|https/.test(req.url) ? req.url : `${environment.apiUrl}${req.url}`;
        req = this.AddTokenHeader(req, this._auth.access_token);
        return next.handle(req).pipe(
            catchError((err) => {
                if (err.status === 401) {
                    return this.headleRefreshToken(req, next);
                }
                return throwError(err);
            })
        );
    }

    AddTokenHeader(req: HttpRequest<any>, token: any, status: boolean = false) {
        if (status) {
            return req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token),
                // url: this.appendURL,
            });
        } else {
            return req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token),
                url: this.appendURL,
            });
        }
    }

    headleRefreshToken(req: HttpRequest<any>, next: HttpHandler) {
        return this._auth.generateRefreshToken().pipe(
            switchMap((data: any) => {
                this._auth.setToken(data);
                return next.handle(this.AddTokenHeader(req, data, true));

            }),
            catchError((err: any) => {

                // ถ้า Refresh Token ไม่ได้จะให้เด้งไปที่หน้าไหน
                // window.location.reload()
                // window.location.href = "https://auth.legitid.org/sign-in?redirectTo=http:%2F%2Flocalhost:4200"
                // window.location.href = "http://localhost:4200"
                return throwError(err);
            })
        );
    }
}
