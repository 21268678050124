<br><br>
<div class="container">
    <div class="row">
        <div class="col-12">
            <button pButton class="p-button-text p-button-sm p-button-raised ms-3" label="Back"
                (click)="backDashboard()">
                <span class="material-symbols-outlined">
                    arrow_back
                </span>
            </button>
        </div>
    </div>
    <br>
    <div class="row">

        <div class="col-12">
            <div class="d-flex">
                <span class="material-symbols-outlined fs-1  me-1">
                    mobile_friendly
                </span>
                <span class="fs-3">App Version</span>
                <button pButton class="p-button-text p-button-sm p-button-raised ms-3" label="Add Version"
                    (click)="actionView = true">
                    <span class="material-symbols-outlined">
                        add
                    </span>
                </button>
            </div>
        </div>
    </div>
    <br><br>
    <div class="row">
        <div class="col-12">
            <table class="table table-hover  ">
                <thead>
                    <tr>
                        <th>Version Name</th>
                        <th>Path Download</th>
                        <th class="text-end">Status</th>
                        <th class="text-end">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let v of verItems?.Items">
                        <td>{{v.name}}</td>
                        <td>
                            <div class="d-flex">
                                <span>{{v.download}}</span>
                                <a [href]="v.download">
                                    <span class="material-symbols-outlined ms-2">
                                        download
                                    </span>
                                </a>

                            </div>
                        </td>
                        <td class="text-end">
                            <span *ngIf="v.status ==1" class="text-success fw-bolder ">ON LINE</span>
                        </td>
                        <td class="text-end">
                            <button class="btn btn-danger btn-sm" (click)="deleteVersion(v)">
                                <div class="d-flex">
                                    <span class="material-symbols-outlined fs-5">
                                        delete
                                    </span>
                                    Delete
                                </div>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<p-dialog header="Add Version" [(visible)]="actionView" [breakpoints]="{ '960px': '90vw' }" [style]="{ width: '50vw' }"
    (onHide)="close()">
    <app-create-app (update)="update($event)"></app-create-app>

</p-dialog>