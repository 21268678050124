import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewVersionComponent } from './view-version.component';
import { CreateAppComponent } from './create-app/create-app.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';
import { UploadFileModule } from '../upload-file/upload-file.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ViewVersionComponent,
    CreateAppComponent],
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    TableModule,
    UploadFileModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ViewVersionComponent, CreateAppComponent],
})
export class ViewVersionModule { }
