<div class="row" [formGroup]="formVersion">
    <div class="col-12 text-center">
        <div class="box-image">
            <app-upload-file (FileUpload)="uploadFile($event)">
                <ng-container>
                    <!-- <span class="material-symbols-outlined fs-1 mt-3">
                        image
                    </span> -->
                    <span class="material-symbols-outlined fs-1 mt-3">
                        cloud_upload
                    </span>
                    <br>
                    <span>{{nameFile}}</span>
                </ng-container>
            </app-upload-file>
        </div>
        <br>
        <div class="box-load">
            <div class="progress" *ngIf="progress">
                <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
            </div>
        </div>
        <p>Version Name</p>
        <input type="text" class="form-control" formControlName="ver_name">
        <br><br>
        <button class="btn btn-primary" (click)="createVersion()" *ngIf="!fileLoad">UPLOAD</button>
    </div>
</div>