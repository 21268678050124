import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppURL } from './app.url';
import { CompanyComponent } from './pages/company/company.component';
import { ViewVersionComponent } from './shareds/view-version/view-version.component';
import { AuthGuard } from './guards/auth.guard';
import { PublicVersionComponent } from './shareds/public-version/public-version.component';



const routes: Routes = [
  { path: '', redirectTo: AppURL.organization, pathMatch: 'full' },

  {
    path: AppURL.organization, children: [
      { path: '', component: CompanyComponent },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: AppURL.version, children: [
      { path: ':ogid', component: ViewVersionComponent },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: AppURL.public_version, children: [
      { path: ':ogid', component: PublicVersionComponent },
    ],
  },
  { path: '**', loadComponent: () => import('./pages/notfound/notfound.component') }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
