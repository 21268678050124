import { Injectable } from '@angular/core';
import * as moment from 'moment';
// import { IBreadcrumb } from '../interfaces/breadcrumb.interface';

@Injectable({
  providedIn: 'root',
})
export class ConvertService {
  constructor() { }

  //#region Breadcrumb items

  // private _breadcrumbItems: IBreadcrumb[] = [];

  // breadcrumbs() {
  //   return this._breadcrumbItems;
  // }

  // setBreadcrumbs(items: IBreadcrumb[]) {
  //   this._breadcrumbItems = [...[{ name: 'หน้าหลัก', link: ['/'] }], ...items];
  // }

  //#endregion

  /** แปลงข้อมูลจาก json เป็น FormData */
  paseFormData(json: Object) {
    const form = new FormData();
    (function serializeObjects(data: Object, key?: string) {
      Object.entries(data || {}).forEach(([name, value]) => {
        const fieldName = key ? `${key}[${name}]` : name;
        switch (typeof value) {
          case 'object':
            // เปลี่ยนชื่อใหม่หากเป็นรูปแบบ Array [] ต้องเป็น .
            if (value instanceof File || value instanceof Blob)
              form.append(fieldName.replace(`[${name}]`, `.${name}`), value);
            else serializeObjects(value, fieldName);
            break;
          case 'string':
            form.append(fieldName, value);
            break;
          case 'number':
          case 'boolean':
          case 'bigint':
            form.append(fieldName, value.toString());
            break;
        }
      });
    })(json);
    return form;
  }

  paseFormData2(json: Object) {
    const form = new FormData();
    (function serializeObjects(data: Object, key?: string) {
      Object.entries(data || {}).forEach(([name, value]) => {
        const fieldName = key ? `${key}[${name}]` : name;
        switch (typeof value) {
          case 'object':
            // เปลี่ยนชื่อใหม่หากเป็นรูปแบบ Array [] ต้องเป็น .
            if (value instanceof File || value instanceof Blob)
              form.append(fieldName.replace(`[${name}]`, `[${name}]`), value);
            else serializeObjects(value, fieldName);
            break;
          case 'string':
            form.append(fieldName, value);
            break;
          case 'number':
          case 'boolean':
          case 'bigint':
            form.append(fieldName, value.toString());
            break;
        }
      });
    })(json);
    return form;
  }

  /** แปลงข้อมูล base64 เป็น blob */
  parseBlobBase64(rawBase64: string) {
    const replaces = rawBase64.split(',');
    const type = replaces[0].replace('data:', '').replace(';base64', '');
    const b64Data = replaces[1];
    const byteCharacters = atob(b64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) byteNumbers[i] = byteCharacters.charCodeAt(i);
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  }

  convertDateDefault(value: any) {
    if (moment(value).format('YYYY-MM-DD') == 'Invalid date') {
      let date = value.split('/');
      return `${date[2]}-${date[1]}-${date[0]}`;
    } else {
      let text = `${value}`;
      if (text.indexOf('/') >= 0) {
        let date = value.split('/');
        if (date) return `${date[2]}-${date[1]}-${date[0]}`;
      } else {
        let date = moment(value).format('YYYY-MM-DD');
        return date;
      }
      return value;
    }
  }


  thaiDate(date: any) {
    let year = moment(date).add(543, 'year').format('YYYY')
    let month = this.converMonth(moment(date).format('MM'))
    let day = moment(date).format('DD')
    let convertDate = `${day} ${month} ${year}`;
    return convertDate;

  }

  converMonth(m: string) {
    if (m == '01') return 'มกราคม';
    if (m == '02') return 'กุมภาพันธ์';
    if (m == '03') return 'มีนาคม';
    if (m == '04') return 'เมษายน';
    if (m == '05') return 'พฤษภาคม';
    if (m == '06') return 'มิถุนายน';
    if (m == '07') return 'กรกฎาคม';
    if (m == '08') return 'สิงหาคม';
    if (m == '09') return 'กันยายน';
    if (m == '10') return 'ตุลาคม';
    if (m == '11') return 'พฤศจิกายน';
    if (m == '12') return 'ธันวาคม';
    return;
  }
}
