import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { AppService } from './services/app.service';
import { HttpClient } from '@angular/common/http';
import { PrimeNGConfig } from 'primeng/api';
import { KeycloakService } from 'keycloak-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  side: boolean = true;
  checkWidth: number = 0;
  constructor(private _auth: AuthService,
    public app: AppService,
    private primengConfig: PrimeNGConfig,
    private kc: KeycloakService) {
    this.checkWidth = window.outerWidth;

  }

  ngOnInit() {
    this.primengConfig.ripple = true;

  }



  show: number = 0
  @HostListener('click', ['$event.target'])
  onClick(event: any) {
    this.show++
    if (this.show > 1) {
      this.app.contactAction = false;
      this.show = 0
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWidth = event.target.outerWidth;
  }

  action() {
    this.app.menuAction = !this.app.menuAction
  }
}
