import { Component, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertToast } from 'src/app/interfaces/alert.interface';
import { ALERT_TOAST_EVENT } from 'src/app/services/alert.service';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnDestroy {
  private _subscribe1?: Subscription;
  public items: AlertToast[] = [];

  @Input('timeout')
  public timeout: number = 5000;

  constructor() {
    this._subscribe1 = ALERT_TOAST_EVENT.subscribe(ev => {
      this.items.push(ev);
      timer(this.timeout).subscribe(() => this.items.pop());
    });
  }

  ngOnDestroy(): void {
    this._subscribe1?.unsubscribe();
  }

  /** close */
  close(item: AlertToast) {
    const index = this.items.indexOf(item);
    if (index >= 0) this.items.splice(index, 1);
  }

  /** get type of class */
  type(item: AlertToast) {
    return {
      'primary': 'blue',
      'success': 'green',
      'warning': 'yellow',
      'error': 'red',
    }[item.type || 'error'];
  }
}
