<br><br>
<div class="container">
    <div class="row">
        <div class="col-12">
            <button pButton class="p-button-text p-button-sm p-button-raised" label="Add Organization"
                (click)="AddCompany()">
                <span class="material-symbols-outlined">
                    add
                </span>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6 col-12" *ngFor="let cm of companyItem?.Items">
            <div class="shadow-sm p-3 mb-5 bg-body">
                <div class="row">
                    <div class="col-6">
                        <h3> {{cm.company_name}}</h3>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="d-flex start license  action" (click)="SettingCompany(cm)">
                            <span class="material-symbols-outlined me-1">
                                settings
                            </span>
                            <span>Organization</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="d-flex  license justify-content-end end action" (click)="AppVersion(cm)">
                            <span class="material-symbols-outlined  me-1">
                                mobile_friendly
                                </span>
                            <span>App Version</span>
                        </div>
                    </div>


                </div>
                <div class="row mt-2">
                    <!-- <div class="col-6">
                        <div class="d-flex license start action" (click)="ToSystem(cm)">
                            <span class="material-symbols-outlined  me-1">
                                grid_view
                            </span>
                            <span>System</span>
                        </div>
                    </div> -->

                    <!-- <div class="col-12">
                        <div class="d-flex license justify-content-end end action" (click)="AddLicense()">
                            <span class="material-symbols-outlined me-1">
                                download
                            </span>
                            <span>Download</span>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog [header]="textHeader" [(visible)]="actionView" [breakpoints]="{ '960px': '90vw' }" [style]="{ width: '50vw' }"
    (onHide)="close()">
    <app-settig-company *ngIf="actionUse == actionList.company" [model]="company"
        (updateItem)="updateItem($event)"></app-settig-company>

</p-dialog>