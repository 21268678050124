import { Component, EventEmitter, Input, Output } from '@angular/core';
import { settingCompany } from './settig.interface';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-settig-company',
  templateUrl: './settig-company.component.html',
  styleUrls: ['./settig-company.component.scss']
})
export class SettigCompanyComponent {
  modelItem?: settingCompany
  @Input('model') set item(v: any) {
    if (!v) return
    this.modelItem = v;
    this.form.patchValue(this.modelItem)
  }

  @Output('updateItem') updateItem = new EventEmitter()

  constructor(private http: HttpClient,
    private fb: FormBuilder,
    private convert: ConvertService) {
    this.formControlName();
  }

  form: FormGroup | any

  formControlName() {
    this.form = this.fb.group({
      company_name: '',
      company_logo_path: ''
    })
  }

  viewImage: string = ""
  uploadFile(e: any) {
    console.log(e);
    this.viewImage = e.base64
    this.form.get('company_logo_path').setValue(e.file)
  }

  createCompany() {
    // let model = this.convert.paseFormData(this.form.value)
    this.http.post(`/api/og/create`, this.form.value).subscribe({
      next: (res) => {
        this.updateItem.emit(res)
      }, error: (err) => { }
    })
  }

  updateCompany() {
    // let model = this.convert.paseFormData(this.form.value)
    this.http.put(`/api/og/update/${this.modelItem?.id}`, this.form.value).subscribe({
      next: (res) => {
        this.updateItem.emit(res)
      }, error: (err) => { }
    })
  }


}
