import { environment } from "src/environments/environment";
import { AppURL } from "../app.url";
// import { AuthenURL } from "../pages/authen/authen.url";

/** Base class extends */
export class AppConfig {

    // ##### List of enpoint connect urls
    ApiURL = environment.apiUrl;

    // ##### List of enpoint urls
    AppURL = AppURL;
    // AuthenURL = AuthenURL;

}