import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, throwError } from 'rxjs';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-create-app',
  templateUrl: './create-app.component.html',
  styleUrls: ['./create-app.component.scss']
})
export class CreateAppComponent {
  ogid: any;
  @Output('update') update = new EventEmitter()
  constructor(private http: HttpClient,
    private form: FormBuilder,
    private con: ConvertService,
    private ac: ActivatedRoute) {
    this.ogid = this.ac.snapshot.params['ogid']
  }
  formVersion: FormGroup | any = this.form.group({
    ver_file: null,
    ver_name: '',
  })

  viewImage: string = ""
  nameFile: string = "Upload File"
  uploadFile(e: any) {
    this.nameFile = e.file_name
    this.viewImage = e.base64
    this.formVersion.get('ver_file').setValue(e.file)
    this.fileLoad = false
  }

  fileLoad: boolean = false;
  progressLoad(e: any) {
    this.fileLoad = true
  }

  progress: any
  createVersion() {
    let model = this.con.paseFormData(this.formVersion.value)
    this.http.post(`/api/version/create/${this.ogid}`, model, { reportProgress: true, observe: 'events' }).pipe(
      map((event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.progress = Math.round((100 / event.total) * event.loaded);
        } else if (event.type == HttpEventType.Response) {
          this.progress = null;
        }
      }),
      catchError((err: any) => {
        this.progress = null;
        alert(err.message);
        return throwError(err.message);
      })
    ).subscribe({
      next: () => {
        // this.update.emit(true)
      },
      complete: () => {
        this.formVersion.reset()
        this.update.emit(true)
      }
    })
  }
}
